.contact-container {
    width: 100%;
    margin: 0 auto; /* Center the container */
    padding: 2rem;
    /* margin-top: -30%; */
  }
  
  h1 {
    font-size: 2rem;
    font-family: monospace;
    margin-bottom: 1rem;
    color: white;
  }
  
  .contact-h {
    margin-bottom: 3em;
    line-height: 1.6;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: white;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Add gap between form fields */
  }
  
  .contact-row {
    display: flex;
    gap: 1.5rem; /* Gap between the Name and Email fields */
  }
  
  .contact-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .contact-field .top{
    width: 40vw;
  }

  .contact-field .bottom{
    width: 82vw;
  }
  .message{
    height: 40vh;
  }

  label {
    font-size: 1rem;
    font-family:monospace;
    color: white;
    margin-bottom: 0.5rem;
    text-align: start;
  }
  
  input,
  textarea {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid black;
    border-radius: 4px; /* Rounded corners */
    transition: border-color 0.3s ease-in-out;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  input:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    resize: vertical;
    height: 150px;
  }
  
  .btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-family: monospace;
    color: white;
    background-color: #000000;
    text-decoration: none;
    border: 2px solid black;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .btn:hover {
    background-color: #ffffff;
    color: black;
    border-color: black;
    border-width: 3px;
  }
  
  .form-status {
    margin-top: 1rem;
    font-size: 1rem;
    color: #28a745; /* Success color */
  }

  .contact-container .btn{
    margin-left: 0%;
  }

  /* Media Queries for responsiveness */
/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .contact-container {
      padding: 1.5rem;
      /* margin-top:-40%; */
    }
    
    h1 {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 992px) {
    .contact-container {
      padding: 1rem;
      
    }
    
    h1 {
      font-size: 1.5rem;
    }
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 0.5rem;
      margin-top:10%;
    }
    
    h1 {
      font-size: 1.25rem;
    }
    
    /* .contact-field input,
    .contact-field textarea {
      font-size: 0.875rem;
    } */
    
    .btn {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }
  } }

  @media (max-width:350px) {
    .contact-h {
      text-align: left;
      font-size: 0.8rem;;
    }
  }