/* General Styles */
select {
  appearance: none; /* Removes the default dropdown arrow */
  -webkit-appearance: none; /* Removes the default dropdown arrow for Safari */
  -moz-appearance: none; /* Removes the default dropdown arrow for Firefox */
  background: transparent; /* Make background transparent to avoid default icon */
  padding-right: 2rem; /* Adjust padding to accommodate custom icon */
  position: relative;
}

/* Position your custom dropdown icon */
.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent icon from blocking clicks */
}

/* Additional styling to ensure select appearance */
select {
  width: 100%; /* Full width for responsive designs */
  border: 1px solid #ccc;
  padding: 10px;
  font-family: monospace;
  font-size: 1.2rem;
  color: #333;
}

.services-page {
  background-repeat: no-repeat;
  background-size: cover;
}

.services-container {
  text-align: center;
  padding: 2rem;
}

.services-content {
  box-sizing: border-box;
}

.services-container h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  display: flex;
  text-align: left;
  padding-top: 10%;
  color: rgb(179, 223, 231);
  font-family: monospace;
}

.service-section {
  margin-top: 20%;
}

.services-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 8% 0 2rem 0;
  padding-bottom: 5%;
}

.service-card {
  background: linear-gradient(to right, #0c162d, #c8c0d0);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 30vw;
  text-align: left;
  margin-bottom: 10%;
  position: relative;
  animation: float 2s ease-in-out infinite;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  animation-play-state: paused;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.service-card h2 {
  font-size: 1.8rem;
  font-family: monospace;
  margin-bottom: 3rem;
  text-align: center;
  color: rgb(124, 251, 245);
}

.faq-dropdowns details {
  margin-bottom: 1rem;
}

.faq-dropdowns summary {
  cursor: pointer;
  font-weight: bold;
  color: rgb(177, 238, 235);
}

.faq-dropdowns p {
  margin-top: 0.5rem;
  color: white;
  line-height: 1.3;
}

.slider-section {
  margin-top: 0;
}

.slider-section h2 {
  font-size: 2rem;
  font-family: monospace;
  margin-bottom: 1rem;
}

/* Hide the default dropdown marker */
details {
  margin-bottom: 10px;
  background-color: transparent;
  color: black;
  border-bottom: 1px solid #333;
  padding: 10px;
  font-family: monospace;
  text-align: left;
  line-height: 2.8;
  position: relative;
  cursor: pointer; /* Ensure cursor is pointer */
}

summary {
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none; /* Remove default list styling */
  border: none; /* Remove any default border */
  padding: 0; /* Remove any default padding */
}

/* Ensure no default marker or icon is visible */
details summary::-webkit-details-marker {
  display: none; /* Hide the default marker in WebKit browsers */
}

details summary::-moz-details-marker {
  display: none; /* Hide the default marker in Firefox */
}

details summary::-ms-expand {
  display: none; /* Hide the default marker in IE/Edge */
}

.dropdown-icon {
  font-size: 1.5rem;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

details[open] .dropdown-icon {
  transform: rotate(180deg);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .service-card {
    width: 40vw; /* Adjust for medium screens */
  }
}

@media (max-width: 900px) {
  .service-card {
    width: 50vw; /* Adjust for smaller screens */
  }
}

@media (max-width: 600px) {
  .services-container h1 {
    font-size: 2.5rem; /* Adjust for small screens */
  }

  .service-card {
    width: 80vw; /* Adjust for very small screens */
    margin: 0.5rem; /* Reduce margin */
  }

  .services-cards {
    flex-direction: column;
    align-items: center; /* Center align cards */
  }
}
