@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Sacramento&display=swap');

.about-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}

.about-intro img {
  width: 40%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.about-intro-text {
  width: 60%;
  padding: 20px;
}

.moving-bar {
  background: rgb(179, 223, 231);
  color: #1e2029;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.moving-bar:before {
  content: "Innovating the digital future for you... Innovating the digital future for you..."; 
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: rgb(179, 223, 231);
  color: #1e2029;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  animation: moveBar 10s linear infinite;
}

@keyframes moveBar {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.about-intro h1 {
  font-family: monospace;
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: rgb(179, 223, 231);
  line-height: 1.6;
}

.about-intro p {
  font-family: monospace;
  font-size: 18px;
  color: white;
}

.about-text-container {
  flex: 1;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.about-h1 {
  font-family: monospace;
  font-size: 3rem;
  margin-left: -60%;
  margin-top: 10%;
  color: rgb(179, 223, 231);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.about-h1.animate {
  opacity: 1;
  transform: translateY(0);
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.about-main-content {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  width: 100vw;
}

.about-image-container {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 20px;
}

.about-main-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}

.about-text-container h1 {
  color: white;
}

.about-details {
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
  opacity: 1;
}

.about-options {
  display: flex;
  flex-direction: column;
  font-family: monospace;
  border-top: 1px solid rgb(150, 142, 153);
  padding-top: 1rem;
  margin-top: auto;
  text-align: left;
}

.about-option {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.about-option:hover {
  color: #333;
  transform: scale(1.05);
}

.about-option h2 {
  margin: 0;
  font-size: 1.25rem;
  color: white;
}

.about-details p {
  font-size: 1.2em;
  line-height: 1.8;
  color: white;
  text-align: left;
}

.about-text-container .about-details {
  position: absolute;
  border-radius: 20px;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.about-option:hover ~ .about-text-container .about-details {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 1200px) {
  .about-intro-text {
    padding-left: 10%;
    text-align: left;
  }
  .about-details p {
    font-size: 1.06em;
    line-height: 1.4;
  }
  .about-options {
    margin-top: 70%;
  }
}
@media (max-width: 968px) {
  .about-options {
    margin-top: 80%;
  }
}
@media (max-width: 768px) {
  .about-intro {
    flex-direction: column;
  }
  .about-intro img {
    width: 100%;
    margin-bottom: 10px;
  }
  .about-intro-text {
    width: 110%;
    padding: 10px;
    font-size: 0.8rem;
    text-align: left;
  }
  .moving-bar {
    font-size: 14px;
  }
  .about-h1 {
    font-size: 2.2rem;
    margin-left: 0;
  }
  .about-main-content {
    flex-direction: column;
    margin-top: 5%;
  }
  .about-options {
    margin-top: 70%;
  }
  .about-text-container h1 {
    font-size: 1.5rem;
  }
  .about-details p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  .about-text-container {
    position: relative;
    border-radius: 20px;
    margin-top: 10%;
    height:100vh;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
}
.about-details {
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(0);
    opacity: 1;
    height:35vh;
}

}
@media (max-width: 700px) {
  .about-options {
    margin-top: 80%;
  }
}
@media (max-width: 500px) and (min-width: 300px) {
  .about-intro {
    flex-direction: column;
  }
  .about-intro img {
    width: 100%;
    margin-bottom: 10px;
  }
  .about-intro-text {
    width: 110%;
    padding: 10px;
    font-size: 0.8rem;
    text-align: left;
  }
  .moving-bar {
    font-size: 14px;
  }
  .about-h1 {
    font-size: 2.2rem;
    margin-left: 0;
  }
  .about-main-content {
    flex-direction: column;
    margin-top: 5%;
  }
  .about-options {
    margin-top: 80%;
  }
  .about-details p {
    font-size: 0.7rem;
    line-height: 1.6;
  }
  .about-text-container {
    position: relative;
    border-radius: 20px;
    margin-top: 10%;
    height:100vh;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
}
.about-details {
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(0);
    opacity: 1;
    height:35vh;
}
}
@media (max-width: 860px) {
  .about-options{
    margin-top: 90%;
  }
  .about-details p {
    font-size: 0.8rem;
    line-height: 1.6;
  }
  }
  @media (max-width: 820px) {
    .about-options{
      margin-top: 80%;
    }
    .about-details p {
      font-size: 0.7rem;
      line-height: 1.6;
    }
    }
@media (max-width: 550px) and (min-width:410px){
.about-options{
  margin-top: 90%;
}
.about-details p {
  font-size: 0.7rem;
  line-height: 1.6;
}
}
@media (max-width: 350px) {
  .about-options{
    margin-top: 90%;
  }
  .about-details p {
    font-size: 0.6rem;
    line-height: 1.6;
  }
  }