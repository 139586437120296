body {
  background: linear-gradient(to bottom, #251430, #243B55, rgb(61, 68, 75));
  color: #fff;
  font-family: monospace;
  margin: 0;
  padding: 0;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.hero-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-text {
  font-size: 4rem;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: bold;
  color: #fff;
  letter-spacing: 6px;
  transform: translateY(100%);
  animation: slide-up 2s ease-out forwards;
}

.typewriter-bold {
  font-size: 1.8em;
  color: white;
}

.typewriter-green {
  font-size: 1.5em;
  color: rgb(179, 223, 231);
}

.hero-content p {
  font-size: 1.5em;
}

.about,
.services,
.community,
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
}

.services {
  color: white;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.services.slide-up {
  opacity: 1;
  transform: translateY(0);
}

.about h2,
.community h2,
.contact h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: monospace;
}

.about p,
.services p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: white;
  margin-bottom: 20px;
  max-width: 800px;
}

.services ul {
  list-style: none;
  padding: 0;
}

.services li {
  font-size: 1.2em;
  margin: 10px 0;
}

.community-item {
  margin: 30px 0;
}

.community-item h3 {
  font-size: 2em;
  margin-bottom: 10px;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact label {
  margin: 10px 0;
  font-size: 1.2em;
}

.contact input,
.contact textarea {
  width: 80%;
  padding: 10px;
  margin-top: 5px;
}

.contact button {
  margin-top: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: black;
  background-color: white;
  text-decoration: none;
  border: 2px solid black;
  transition: background-color 0.3s ease;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="2" fill="transparent"/><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="1" fill="transparent" opacity="0.5"/></svg>'), auto;
}

.btn:hover {
  background-color: transparent;
  color: white;
  border-color: white;
}

.about-info .btn {
  margin-left: 0%;
}

@media (max-width: 1024px) {
  .hero-text {
    font-size: 3.2rem;
  }

  .scroll-line {
    left: 0%;
  }

  .typewriter-bold {
    font-size: 1.5em;
  }

  .typewriter-green {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .hero-text {
    font-size: 2.2rem;
  }

  .scroll-line {
    left: -20%;
  }

  .typewriter-bold {
    font-size: 1.2em;
  }

  .typewriter-green {
    font-size: 1em;
  }

  .about h2 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }

  .about .btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .about h2 {
    font-size: 1.5rem;
  }

  .about p {
    font-size: 0.9rem;
  }

  .about .btn {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.about-us,
.services {
  margin-top: 40px;
  text-align: center;
}

.about-us h2,
.services h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about-us p,
.services p {
  font-size: 18px;
  margin-bottom: 20px;
}

.services-button,
.details-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.services-button:hover,
.details-button:hover {
  background-color: #ccc;
}

.service-item {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
}

.scroll-line {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 500px) and (min-width: 300px) {
  .hero-text {
    font-size: 1.8rem;
    letter-spacing: 3px;
  }

  .typewriter-bold {
    font-size: 1.5em;
  }

  .typewriter-green {
    font-size: 1.3em;
  }

  .about h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .about p {
    font-size: 0.8rem;
  }

  .about .btn {
    font-size: 0.7rem;
    padding: 5px 10px;
  }

  .scroll-line {
    width: 80%;
    left: 0%;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.about-us,
.services {
  margin-top: 40px;
  text-align: center;
}

.about-us h2,
.services h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about-us p,
.services p {
  font-size: 18px;
  margin-bottom: 20px;
}

.services-button,
.details-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.services-button:hover,
.details-button:hover {
  background-color: #ccc;
}

.service-item {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
}

.scroll-line {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}