/* General footer styling */
.footer {
  background-color: transparent;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-top: 2px dashed white;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-section {
  flex: 1;
  margin: 0 10px;
  min-width: 200px;
}

.footer-logo {
  max-width: 150px; /* Adjust the size of the logo as needed */
}

.footer-section h3 {
  margin-top: 0;
  color: #fff;
  font-size: 1.7em; /* Adjust font size if needed */
  line-height: 1.4; /* Adjust line height for readability */
}

.footer-section p {
  margin: 5px 0;
  color: #fff;
  line-height: 1.6; /* Adjust line height for readability */
}

.footer-section ul {
  list-style: none;
  padding: 0;
  
}

.footer-section ul li {
  margin: 5px 0;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="2" fill="transparent"/><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="1" fill="transparent" opacity="0.5"/></svg>'), auto;
}

.footer-section ul li a {
  font-size:1rem;
  color: #fff;
  text-decoration: none;
  line-height: 1.6;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="2" fill="transparent"/><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="1" fill="transparent" opacity="0.5"/></svg>'), auto;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size:1.8rem;
}

.social-icon {
  margin: 0 25px; /* Adjust the space between icons */
  color: #fff; /* Ensure icons are white */
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #f0f0f0;
  transform: scale(1.1);
}

.contact-link {
  color: #fff;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.footer-bottom p {
  border-top: 1px solid #444;
  padding-top: 10px;
  color: #fff; /* Ensure the footer-bottom text is white */
}
