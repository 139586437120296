.analytics {
  font-family: monospace;
  text-align: center;
  padding: 60px 20px;
  color: #fff;
}

.analytics h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: white;
}

.analytics-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: pointer;
}

.card {
  background-color: #262b3e;
  border-radius: 12px;
  padding: 30px;
  width: 300px;
  margin: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="2" fill="transparent"/><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="1" fill="transparent" opacity="0.5"/></svg>'), auto;
}

.card:hover {
  transform: translateY(-10px);
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
}

.number {
  font-size: 3rem;
  color: rgb(179, 223, 231);
  font-weight: bold;
}
