/* Contact Section */
.reach-out-section {
  padding: 50px 20px;
  margin-top: 10%;
}

.reach-out-container {
  display: flex;
  justify-content: space-between;
  font-family: monospace;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.collage-container {
  display: flex;
  gap: 20px;
  position: relative;
  width: 50%;
}

.collage-image {
  object-fit: cover;
  border-radius: 10px;
}

.image1 {
  height: 300px;
  width: 200px;
  z-index: 3;
}

.image2 {
  height: 400px;
  width: 250px;
  margin-top: -100px; /* Adjust this value to overlap */
  z-index: 2;
}

.image3 {
  height: 200px;
  width: 150px;
  margin-top: -50px; /* Adjust this value to overlap */
  z-index: 1;
}

.reach-out-text {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.reach-out-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
  text-align: left;
}

.reach-out-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: white;
  line-height: 1.6;
  text-align: left;
}

.reach-out-button {
  padding: 15px 20px;
  background-color: white;
  color: black;
  font-size: 1rem;
  border-radius: 0px;
  cursor: pointer;
  max-width: 20vw;
  margin-top: 5%;
  justify-content: start;
}

.reach-out-button:hover {
  background-color: transparent;
  color: white;
  border-color: white;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .reach-out-container {
      flex-direction: column; /* Stack the content vertically */
      text-align: center;
  }

  .image-collage {
      display: none; /* Hide images on mobile screens */
  }

  .reach-out-text {
      width: 100%; /* Make text container span the full width */
  }

  .reach-out-title, .reach-out-description {
      text-align: center; /* Center the text */
  }

  .reach-out-button {
      max-width: 100%; /* Make the button span full width if needed */
  }
}

@media (max-width: 350px) {
  .reach-out-title {
    text-align: left;
  }
  .reach-out-description {
    text-align: left;
  }
}