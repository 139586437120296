body {
    margin: 0;
    font-family: 'sans-serif';
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="2" fill="transparent"/><circle cx="12" cy="12" r="10" stroke="lightblue" stroke-width="1" fill="transparent" opacity="0.5"/></svg>'), auto;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }


button{
  font-family: monospace;
}