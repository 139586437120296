.header {
  position: relative;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure overlay doesn't overflow */
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Place the overlay behind text and other content */
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space is distributed between brand, links, and socials */
  padding: 1rem;
  position: relative;
  top: -40%;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar is above other content */
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 15rem; /* Space between logo and menu items */
}

.navbar-brand a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.navbar-brand img {
  height: 150px; /* Adjust size of the logo */
}

.navbar-links {
  display: flex;
  align-items: center;
  flex-grow: 0.8; /* Allows links to take up remaining space */
}

.navbar-links a {
  color: white;
  text-decoration: none;
  margin-left: 20px; /* Space between menu items */
  font-size: 1.3rem; 
}

.navbar-socials {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push social links to the right end */
}

.navbar-socials a {
  color: white;
  margin-left: 15px; /* Space between social icons */
  font-size: 1.5rem; /* Adjust icon size as needed */
}

.hero-content {
  padding: 100px 20px;
}

.hero-content h1 {
  font-size: 5em;
  margin-bottom: 10px;
  color: black;
  z-index: 1; /* Ensure content is above the overlay */
}

.hero-content p {
  font-size: 1.5em;
  z-index: 1; /* Ensure content is above the overlay */
}

.home-container {
  margin-top: 0; /* Adjust to accommodate fixed header */
}

/* Large Screens (1200px and up) - Default styles */
@media (min-width: 1200px) {
  .navbar-links a {
    font-size: 1.3rem;
    margin-left: 20px;
  }
  
  .navbar-socials a {
    font-size: 1.5rem;
    margin-left: 15px; /* Adjusted spacing */
  }
}

/* Medium Screens (992px to 1199px) */
@media (max-width: 1199px) and (min-width: 992px) {
  .navbar {
    top: -30%;
    padding: 0.5rem;
  }

  .navbar-brand {
    margin-right: 0;
  }
  
  .navbar-links {
    justify-content: center;
  }
  
  .navbar-links a {
    font-size: 1.1rem;
  }
  
  .navbar-socials {
    margin-left: 20%;
  }
  
  .navbar-socials a {
    font-size: 1.2rem;
  }
}

/* Small Screens (768px to 991px) */
@media (max-width: 991px) {
  .header {
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 3.8em;
    margin-top: -20%;
  }
  
  .navbar {
    top: -30%;
    padding: 0.5rem;
  }
  
  .navbar-brand img {
    height: 20vh;
  }
  
  .navbar-links a {
    font-size: 1rem;
    margin-left: 15px;
  }
  
  .navbar-socials a {
    font-size: 1rem;
    margin-left: 10px; /* Adjusted spacing */
  }
}

/* Extra Small Screens (360px to 767px) */
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
  }

  .navbar-brand img {
    height: 15vh;
  }
  
  .navbar-links {
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .navbar-links a {
    font-size: 0.8rem;
    margin-left: 10px;
  }
  
  .navbar-socials {
    margin-left: 0; /* Reset margin-left */
    justify-content: center;
  }
  
  .navbar-socials a {
    font-size: 0.8rem;
    margin-left: 10px;
  }
}

/* Very Small Screens (below 360px) */
@media (max-width: 360px) {
  .navbar-brand img {
    margin-left: auto;
    margin-right: auto;
  }
  
  .navbar-links {
    flex-grow: 1.8;
  }
  
  .navbar-links a {
    font-size: 0.8rem;
    margin-left: 10px;
  }
  
  .navbar-socials {
    margin-left: 0; /* Reset margin-left */
    justify-content: center;
  }
  
  .navbar-socials a {
    font-size: 0.8rem;
    margin-left: 10px;
  }
}
